import React, { useEffect, useState, Suspense } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import * as EnvConstants from "../src/Constants/EnvConstants";
import { VisitorParamsProvider } from "./Contexts/VisitorParams";
import HomePage from "./Components/Pages/HomePage";
import { RedirectProvider } from "./Contexts/RedirectContext";
import "./App.scss";
import AdtopiaLoadDynamic from "./Utility/AdtopiaLoadDynamic";

import EnvDocView from "./Components/Pages/LUFP_V1/EnvDocView";
import HelmetBasedOnDomain from "./Components/Includes/Layouts/Common/HelmetBasedOnDomain";

const LUFP_V1 = React.lazy(() => import("./Components/Pages/Splits/LUFP_V1"));
const LufpV1PersonalDetails = React.lazy(() => import("./Components/Pages/LUFP_V1/LufpV1PersonalDetails"));
const LufpV1Signature = React.lazy(() => import("./Components/Pages/LUFP_V1/LufpV1Signature"));
const LufpV1IdUpload = React.lazy(() => import("./Components/Pages/LUFP_V1/LufpV1IdUpload"));
const LufpV1CarRegistration = React.lazy(() => import("./Components/Pages/LUFP_V1/LufpV1CarRegistration"));
const LufpV1Lenders = React.lazy(() => import("./Components/Pages/LUFP_V1/LufpV1Lenders"));
const FbPixel = React.lazy(() => import("./Components/Pages/FbPixel"));
const LufpV1Thankyou = React.lazy(() => import("./Components/Pages/LUFP_V1/LufpV1Thankyou"));
const LufpV1CreditAnalysis = React.lazy(() => import("./Components/Pages/LUFP_V1/LufpV1CreditAnalysis"));
const LUFP_V1B = React.lazy(() => import("./Components/Pages/Splits/LUFP_V1B"));
const LUFPV1BPersonalDetails = React.lazy(() => import("./Components/Pages/LUFP_V1B/LUFPV1BPersonalDetails"));
const LUFPV1BSignature = React.lazy(() => import("./Components/Pages/LUFP_V1B/LUFPV1BSignature"));
const LUFPV1BCreditAnalysis = React.lazy(() => import("./Components/Pages/LUFP_V1B/LUFPV1BCreditAnalysis"));
const Loader = React.lazy(()=>import("./Components/Includes/Layouts/Loader/Loader"))
const FollowUp = React.lazy(()=>import("./Components/Pages/Followup/FollowUp"));
const FollowupSignature = React.lazy(()=>import("./Components/Pages/Followup/FollowupSignature"));
const FollowupIdUplod = React.lazy(()=>import("./Components/Pages/Followup/FollowupIdUpload"));
const FollowupLenders = React.lazy(() => import("./Components/Pages/Followup/FollwupLenders"));
const FollowupCarRegistration = React.lazy(() => import("./Components/Pages/Followup/FollowupCarRegistration"));
const FollowupCreditAnalysis = React.lazy(() => import("./Components/Pages/Followup/FollowupCreditAnalysis"));
const FollowupLoader = React.lazy(()=>import("./Components/Pages/Followup/FollowupLoader"))
const ADV_LUFP_1 = React.lazy(()=>import("./Components/Pages/ADV_Page/ADV_LUFP_1"))
const ADV_LUFP_2 = React.lazy(()=>import("./Components/Pages/ADV_Page/ADV_LUFP_2"))
const LUFP_OBV1C = React.lazy(() => import("./Components/Pages/Splits/LUFP_OBV1C"));
const LUFP_V1B_1 = React.lazy(() => import("./Components/Pages/Splits/LUFP_V1B_1"));
const LUFPV1B1PersonalDetails = React.lazy(()=>import("./Components/Pages/LUFP_V1B_1/LUFPV1B1PersonalDetails"));
const LUFPV1B1Signature = React.lazy(()=>import("./Components/Pages/LUFP_V1B_1/LUFPV1B1Signature"));
const LUFPV1B1CreditAnalysis = React.lazy(()=>import('./Components/Pages/LUFP_V1B_1/LUFPV1B1CreditAnalysis'));
const LUFP_V1C = React.lazy(() => import("./Components/Pages/Splits/LUFP_V1C"));
const LufpV1CLenders = React.lazy(() => import("./Components/Pages/LUFP_V1C/LufpV1CLenders"));
const LufpV1cCreditcheckLenders = React.lazy(() => import("./Components/Pages/LUFP_V1B/LufpV1cCreditcheckLenders"));
const EditDetails = React.lazy(() => import("./Components/Pages/EditDetails"));
const CreditCheckEdit = React.lazy(() => import("./Components/Pages/CreditCheckEdit"));
const FollowupEditDetails = React.lazy(() =>import("./Components/Pages/Followup/FollowupEditDetails"));
const FollowupCreditCheckEdit = React.lazy(() => import("./Components/Pages/Followup/FollowupCreditCheckEdit"));

const App = () => {
  const [visitorParameters] = useState({ visitor_parameters: [], data: [] });
  const [redirectUrl] = useState({ redirectUrl: '' });
  // useEffect(() => {
  //   document.title = EnvConstants.AppTitle;
  // }, []);
  return (
    <>
      <HelmetBasedOnDomain />
      <VisitorParamsProvider value={{ visitorParameters }}>
        <RedirectProvider value={{ redirectUrl }}>
          <BrowserRouter>
            <Suspense fallback={<div style={{ color: '#FFFFFF' }}>Loading...</div>}>
              <Route exact path="/" component={HomePage} />
              <Route exact path="/LUFP_V1" component={LUFP_V1} />
              <Route exact path="/lufp-v1-personal-details" component={LufpV1PersonalDetails} />
              <Route exact path="/lufp-v1-signature" component={LufpV1Signature} />
              <Route exact path="/lufp-v1-idupload" component={LufpV1IdUpload} />
              <Route exact path="/lufp-v1-car-registration" component={LufpV1CarRegistration} />
              <Route exact path="/lufp-v1-lenders" component={LufpV1Lenders} />
              <Route exact path={["/fbpixel"]} component={FbPixel} />
              <Route exact path="/lufp-v1-Thankyou" component={LufpV1Thankyou} />
              <Route exact path="/lufp-v1-credit-analysis" component={LufpV1CreditAnalysis} />
              <Route exact path="/loader" component={Loader} />
              <Route exact path={["/google", "/testing"]} component={AdtopiaLoadDynamic} />
              <Route exact path={["/:doc/:docType/:filename"]} component={EnvDocView} />
              <Route exact path="/LUFP_V1B" component={LUFP_V1B} />
              <Route exact path="/lufp-v2-personal-details" component={LUFPV1BPersonalDetails} />
              <Route exact path="/lufp-v2-signature" component={LUFPV1BSignature} />
              <Route exact path="/lufp-v2-credit-analysis" component={LUFPV1BCreditAnalysis} />
              <Route exact path={"/followup"} component={FollowUp}/>
              <Route exact path="/followup-signature" component={FollowupSignature} />
              <Route exact path="/followup-idupload" component={FollowupIdUplod} />
              <Route exact path="/followup-lenders" component={FollowupLenders} />
              <Route exact path="/followup-car-registration" component={FollowupCarRegistration} />
              <Route exact path="/followup-credit-analysis" component={FollowupCreditAnalysis} />
              <Route exact path="/followup-loader" component={FollowupLoader} />
              <Route exact path="/free_claims_checker/ADV_LUFP_1" component={ADV_LUFP_1} />
              <Route exact path="/free_claims_checker/ADV_LUFP_2" component={ADV_LUFP_2} />
              <Route exact path="/LUFP_OBV1C" component={LUFP_OBV1C} />
              <Route exact path="/LUFP_V1B_1" component={LUFP_V1B_1} />
              <Route exact path="/lufp-v1b1-personal-details" component={LUFPV1B1PersonalDetails} />
              <Route exact path="/lufp-v1b1-signature" component={LUFPV1B1Signature} />
              <Route exact path="/lufp-v1b1-credit-analysis" component={LUFPV1B1CreditAnalysis} />
              <Route exact path="/LUFP_V1C" component={LUFP_V1C} />
              <Route exact path="/lufp-v1c-lenders" component={LufpV1CLenders} />
              <Route exact path="/lufp-v1c-creditcheck-lenders" component={LufpV1cCreditcheckLenders} />
              <Route exact path="/edit-details" component={EditDetails} />
              <Route exact path="/edit-credit-check" component={CreditCheckEdit} />
              <Route exact path="/followup-edit-details" component={FollowupEditDetails} />
              <Route exact path="/followup-edit-credit-check" component={FollowupCreditCheckEdit} />
              </Suspense>

            {/*<Route exact path={"/proceed-open-banking"} component={OpenBankingProceed} />
              <Route exact path={["/:enviroment/:project_id/pdf/:doc_type/:filename"]} component={PdfView}/>
              */}
          </BrowserRouter>
        </RedirectProvider>
      </VisitorParamsProvider>
    </>
  );
};

export default App;
